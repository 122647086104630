<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="dark" outlined>
        <v-icon>
          fal fa-plus
        </v-icon>
        <span class="ml-2">
          Adauga banner nou
        </span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Banner nou</v-card-title>
      <v-card-text>


        <v-text-field
          v-model="form.link"
          label="Link"
        ></v-text-field>
        <error-messages :errors="errors.link"></error-messages>

        <image-uploader ref="banner" v-model="form.image_id" label-idle="Incarca bannerul"/>
        <error-messages :errors="errors.image_id"/>

      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close">
          Anuleaza
        </v-btn>
        <v-spacer/>
        <v-btn color="success" class="primary-button" @click="save">
          Creaza
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ImageUploader from '@/components/general-form/ImageUploader'

export default {
  name: 'AddNewCategoryBanner',
  components: {
    ImageUploader,
    ErrorMessages
  },
  props: {
    categoryId: {
      required: true
    },
    position: {
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      form: {
        link: '',
        image_id: '',
        category_id: this.categoryId,
        position: this.position
      },
      errors: {}
    }
  },
  methods: {
    close () {
      this.dialog = false
      this.errors = {}
      this.form.link = ''
      this.form.image_id = ''
      this.$refs.banner.clearImages()
    },
    save () {
      this.$http.post(`product-categories/${this.categoryId}/banners`, this.form)
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: 'Bannerul a fost salvat cu succes.',
            color: 'success'
          })
          this.$emit('added')

          this.close()
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Nu toate campurile obligatorii au fost completate.',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
    }
  }
}
</script>
